import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Nav from "./nav"
import Link from "../link"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"
import { ReactComponent as SvgLogo } from "../../images/logo.svg"
import { ReactComponent as CintLogo } from "../../images/cint-logo.svg"

const Container = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.ziHeader};
  color: ${(props) => props.theme.colorWhite};
`

const Logo = styled.div`
  > div {
    width: ${rem(72)};
    height: ${rem(35)};

    ${(props) =>
      props.large &&
      css`
        @media ${(props) => props.theme.xxlargeUp} {
          width: ${rem(145)};
          height: ${rem(60)};
        }
      `}
  }

  a,
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  a {
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
  }
`

const NavWrapper = styled.div`
  background-color: white;
  color: ${(props) => props.theme.colorPurple};
  position: relative;
  z-index: 99;

  ${(props) =>
    props.padding &&
    css`
      padding-top: ${rem(45)};
      padding-bottom: ${rem(45)};

      @media ${(props) => props.theme.xsmallDown} {
        padding-top: ${rem(15)};
        padding-bottom: ${rem(15)};
      }
    `}

  ${(props) =>
    props.logo === `lucid` &&
    css`
      padding-top: ${rem(30)};
      padding-bottom: ${rem(35)};

      @media ${(props) => props.theme.xsmallDown} {
        padding-top: ${rem(25)};
        padding-bottom: ${rem(25)};
      }

      ${Logo} svg {
        transform: scale(1.3);
        transform-origin: left center;

        @media ${(props) => props.theme.xsmallDown} {
          transform: scale(1.5);
        }
      }
    `}
`

const NavInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Bar = ({ padding, largeLogo, logo, hideNav }) => {
  return (
    <Container>
      <NavWrapper padding={padding} logo={logo}>
        <Grid noTopPadding noBottomPadding>
          <Row>
            <Cell size={23}>
              <NavInnerWrapper>
                <Logo large={largeLogo}>
                  <div>
                    <Link
                      to={hideNav ? `#` : `/`}
                      onClick={() => {
                        window.scrollTo(0, 0)
                      }}
                    >
                      <StoreConsumer>
                        {({ translations, metaWpGlobal }) =>
                          logo == `lucid` ? (
                            <CintLogo aria-label={metaWpGlobal.options.name} />
                          ) : (
                            <SvgLogo aria-label={metaWpGlobal.options.name} />
                          )
                        }
                      </StoreConsumer>
                    </Link>
                  </div>
                </Logo>

                {!hideNav && <Nav />}
              </NavInnerWrapper>
            </Cell>
          </Row>
        </Grid>
      </NavWrapper>
    </Container>
  )
}

Bar.propTypes = {
  sticky: PropTypes.bool,
  padding: PropTypes.bool,
  largeLogo: PropTypes.bool,
  logo: PropTypes.string,
  hideNav: PropTypes.bool,
}

export default Bar
