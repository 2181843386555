import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Newsletter from "./newsletter"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Link from "../link"
import { StoreConsumer } from "../../store"
import { ReactComponent as SvgLogo } from "../../images/logo.svg"

const Container = styled.footer`
  font-size: ${rem(18)};
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
`

const Demo = styled.section`
  background-color: ${(props) => props.theme.colorRed};
`

const DemoText = styled.span`
  @media ${(props) => props.theme.mediumDown} {
    display: block;
  }
`

const LinkGroups = styled.div`
  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(14)};
  }
`

const Links = styled.section`
  html[lang="jp"] & {
    font-size: ${rem(14)};
  }

  a,
  button {
    color: ${(props) => props.theme.colorGreyDark};

    &:hover {
      color: ${(props) => props.theme.colorWhite};
    }
  }
`

const Logo = styled.div`
  a {
    width: ${rem(72)};
    height: ${rem(30)};
    display: block;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const CellCopyright = styled(Cell)`
  color: ${(props) => props.theme.colorGreyDark};

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(12)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    order: 1;
  }
`

const CellPrivacy = styled(Cell)`
  color: ${(props) => props.theme.colorGreyDark};

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(12)};
  }

  a,
  button {
    display: block;

    &:hover {
      color: ${(props) => props.theme.colorWhite};
    }

    &:not(:last-child) {
      margin-bottom: ${rem(8)};
    }
  }
`

const Footer = ({ hideSections }) => {
  const { hideFooterCta, hideFooterNavigation, hideFooterNewsletter } =
    hideSections || {}

  const {
    wordpressAcfOptions: { newsletterForm },
  } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        newsletterForm: options {
          title: newsletter_form_title
          url: newsletter_form_url
        }
      }
    }
  `)

  const [displayCookieButton, setDisplayCookieButton] = useState(false)

  const cookiebotResponded = () => setDisplayCookieButton(true)

  useEffect(() => {
    if (typeof window === `undefined`) return null

    if (window.Cookiebot && window.Cookiebot.hasResponse)
      setDisplayCookieButton(true)

    window.addEventListener(`CookiebotOnAccept`, cookiebotResponded)
    window.addEventListener(`CookiebotOnDecline`, cookiebotResponded)

    return () => {
      window.removeEventListener(`CookiebotOnAccept`, cookiebotResponded)
      window.removeEventListener(`CookiebotOnDecline`, cookiebotResponded)
    }
  }, [])

  const cookieSettingsClick = () => {
    if (!window.Cookiebot) return

    window.Cookiebot.renew()
    document.documentElement.classList.add(`--cint-cookiebot-active`)
  }

  return (
    <StoreConsumer>
      {({ translations, menus, metaWpGlobal }) => (
        <Container>
          {!hideFooterCta && (
            <Demo>
              <Grid>
                <Row pushY={1} pullY={1} mq={{ xsmallDown: { pullY: 6 } }}>
                  <Cell size={19} className="styled-h3">
                    <DemoText
                      dangerouslySetInnerHTML={{
                        __html: translations[`footer_demo_title`],
                      }}
                    />
                    {` `}
                    <Link
                      to={translations[`footer_demo_cta_url`]}
                      className="styled-a"
                      dangerouslySetInnerHTML={{
                        __html: translations[`footer_demo_cta_text`],
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Demo>
          )}

          <Grid>
            {!hideFooterNavigation && (
              <Row>
                {!hideFooterNewsletter && !!newsletterForm.url && (
                  <Cell size={7} mq={{ mediumDown: { size: 23 } }}>
                    <Newsletter data={newsletterForm} />
                  </Cell>
                )}

                <Cell
                  size={11}
                  pushX={hideFooterNewsletter ? 12 : 5}
                  mq={{
                    mediumDown: {
                      size: 23,
                      pushX: 0,
                      pushY: hideFooterNewsletter ? 0 : 3,
                    },
                  }}
                >
                  <LinkGroups>
                    <Row
                      mq={{
                        mediumDown: { pushY: 2 },
                      }}
                    >
                      <Cell size={23}>
                        <Row>
                          <Cell
                            size={10}
                            mq={{
                              mediumDown: { size: 23 },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerPrimary.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>

                          <Cell
                            size={10}
                            pushX={1}
                            pullX={2}
                            mq={{
                              mediumDown: {
                                size: 23,
                                pushX: 0,
                                pullX: 0,
                                pushY: 1,
                              },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerSecondary.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>
                        </Row>
                      </Cell>

                      <Cell
                        size={23}
                        pushY={3}
                        mq={{ mediumDown: { pushX: 0, pushY: 1 } }}
                      >
                        <Row>
                          <Cell
                            size={10}
                            mq={{
                              mediumDown: { size: 23 },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerTertiary.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>

                          <Cell
                            size={10}
                            pushX={1}
                            pullX={2}
                            mq={{
                              mediumDown: {
                                size: 23,
                                pushX: 0,
                                pullX: 0,
                                pushY: 1,
                              },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerQuaternary.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>
                        </Row>
                      </Cell>

                      <Cell
                        size={23}
                        pushY={3}
                        mq={{ mediumDown: { pushY: 1 } }}
                      >
                        <Row>
                          <Cell
                            size={10}
                            mq={{
                              mediumDown: { size: 23 },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerSocialMedia.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>

                          <Cell
                            size={10}
                            pushX={1}
                            pullX={2}
                            mq={{
                              mediumDown: {
                                size: 23,
                                pushX: 0,
                                pullX: 0,
                                pushY: 2,
                              },
                            }}
                          >
                            <Links>
                              <ul>
                                {menus.footerLanguages.map((item, i) => (
                                  <li key={i}>
                                    <Link
                                      to={item.url}
                                      className={item.htmlClass}
                                      data-matomo={
                                        item.eventCategory &&
                                        JSON.stringify([
                                          item.eventCategory,
                                          item.eventAction,
                                          item.eventName,
                                        ])
                                      }
                                      target={
                                        item.newWindow ? `_blank` : undefined
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Links>
                          </Cell>
                        </Row>
                      </Cell>
                    </Row>
                  </LinkGroups>
                </Cell>
              </Row>
            )}

            <Row
              pushY={hideFooterNavigation ? 0 : 5}
              mq={{ xsmallDown: { pushY: hideFooterNavigation ? 0 : 8 } }}
            >
              <Cell size={7}>
                <Logo>
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    <SvgLogo />
                  </Link>
                </Logo>
              </Cell>

              <Cell size={10} pushX={5}>
                <Row>
                  <CellCopyright size={11} mq={{ xsmallDown: { size: 23 } }}>
                    &copy;
                    {` `}
                    {new Date().getFullYear()}
                    {` `}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: metaWpGlobal.options.name,
                      }}
                    />
                  </CellCopyright>

                  <CellPrivacy
                    size={11}
                    pushX={1}
                    mq={{ xsmallDown: { size: 23, pushX: 0 } }}
                  >
                    <Link
                      to={translations[`footer_privacy_url`]}
                      dangerouslySetInnerHTML={{
                        __html: translations[`footer_privacy_title`],
                      }}
                    />

                    {displayCookieButton && (
                      <button
                        type="button"
                        onClick={cookieSettingsClick}
                        dangerouslySetInnerHTML={{
                          __html: translations[`footer_cookie_settings`],
                        }}
                      />
                    )}
                  </CellPrivacy>
                </Row>
              </Cell>
            </Row>
          </Grid>
        </Container>
      )}
    </StoreConsumer>
  )
}

Footer.propTypes = {
  hideSections: PropTypes.object,
}

export default Footer
