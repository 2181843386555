import React from "react"
import styled from "styled-components"
import { rem, em } from "polished"

import Link from "../link"
import { StoreConsumer } from "../../store"
import { ReactComponent as SvgChevronDown } from "../../images/icons/chevron-down.svg"

const Container = styled.nav`
  display: flex;
`

const ItemWrap = styled.div`
  position: relative;
  padding: ${rem(15)} 0;

  &:not(:last-child) {
    margin-right: ${rem(28)};
  }

  &:hover {
    > button {
      opacity: 0.6;

      > svg {
        transform: rotate(180deg);
      }
    }

    > div {
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0);
    }
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    font-size: ${em(20)};

    &:hover {
      opacity: 0.6;
    }

    > svg {
      width: ${rem(12)};
      height: ${rem(12)};
      margin-left: ${rem(8)};
      margin-top: ${rem(5)};
      transition: transform 0.2s ease;
    }
  }
`

const DropdownActivate = styled.button``

const DropdownContainer = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 100%;
  left: ${rem(-40)};
  background-color: ${(props) => props.theme.colorWhite};
  border: 1px solid ${(props) => props.theme.colorGreyLight};
  padding: ${rem(30)} ${rem(50)} ${rem(30)} ${rem(30)};
  transform: translateY(5%);
  transition: transform 0.2s ease, opacity 0.3s ease;

  > a {
    min-width: ${rem(140)};
    white-space: nowrap;
    align-items: center;
    gap: ${rem(20)};

    &:not(:last-of-type) {
      margin-bottom: ${rem(20)};
    }

    > img {
      width: ${rem(70)};
    }

    p {
      color: ${(props) => props.theme.colorPurpleLight};
      font-size: ${em(12)};
      max-width: ${rem(264)};
      margin-bottom: ${rem(12)};
      white-space: normal;
    }
  }

  .arrow {
    position: absolute;
    overflow: hidden;
    transform: translateY(-100%);
    height: ${rem(15)};
    width: ${rem(30)};
    left: ${rem(65)};
    top: 0;

    &::after {
      content: "";
      position: absolute;
      width: ${rem(15)};
      height: ${rem(15)};
      background-color: ${(props) => props.theme.colorWhite};
      border: 1px solid ${(props) => props.theme.colorGreyLight};
      transform: translateX(-50%) translateY(50%) rotate(45deg);
      top: 0;
      left: 50%;
    }
  }
`

const NavItems = () => {
  return (
    <StoreConsumer>
      {({ menus }) => (
        <Container>
          {menus.header.map((link, i) => (
            <ItemWrap key={i}>
              {link.wordpress_parent && (
                <DropdownActivate>
                  <span dangerouslySetInnerHTML={{ __html: link.title }} />
                  <SvgChevronDown aria-hidden="true" />
                </DropdownActivate>
              )}

              {!link.wordpress_parent && (
                <Link
                  to={link.url}
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              )}

              {link.wordpress_parent && (
                <DropdownContainer>
                  <span className="arrow"></span>
                  {link.wordpress_children.map((child, i) => (
                    <Link
                      key={i}
                      to={child.url_child}
                      target={child.newWindowChild ? `_blank` : undefined}
                    >
                      {child.illustration_child != `none` && (
                        <img
                          src={`/product-illustrations/${child.illustration_child}.svg`}
                          alt={child.title_child}
                          role="presentation"
                        />
                      )}
                      <span>
                        {child.title_child}
                        {child.description_child && (
                          <p>{child.description_child}</p>
                        )}
                      </span>
                    </Link>
                  ))}
                </DropdownContainer>
              )}
            </ItemWrap>
          ))}
        </Container>
      )}
    </StoreConsumer>
  )
}

export default NavItems
