import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const mediator = 0.4
const chunk = 4.347

const Container = styled.div`
  /* border: 1px solid red; */
  /* background-color: rgba(255,255,255,0.5); */

  width: auto;
  margin: 0 -${mediator}%;

  ${(props) =>
    props.size !== undefined &&
    css`
      width: ${(props) => props.size * chunk + mediator * 2}%;
    `}

  ${(props) =>
    props.maxSize !== undefined &&
    css`
      max-width: ${(props) => props.maxSize * chunk + mediator * 2}%;
    `}

  ${(props) =>
    props.pushX !== undefined &&
    props.pushX !== `auto` &&
    css`
      margin-left: ${(props) => props.pushX * chunk - mediator}%;
    `}

  ${(props) =>
    props.pushX === `auto` &&
    css`
      margin-left: auto;
    `}

  ${(props) =>
    props.pullX !== undefined &&
    css`
      margin-right: ${(props) => props.pullX * chunk - mediator}%;
    `}

  ${(props) =>
    props.pushY !== undefined &&
    css`
      margin-top: ${(props) => props.pushY * chunk - mediator}%;
    `}

  ${(props) =>
    props.pullY !== undefined &&
    css`
      margin-bottom: ${(props) => props.pullY * chunk - mediator}%;
    `}

  ${(props) =>
    props.mq &&
    Object.entries(props.mq).map(
      ([key, val]) => css`
        @media ${(props) => props.theme[key]} {
          ${val.size !== undefined &&
          val.size !== `auto` &&
          css`
            width: ${val.size * chunk + mediator * 2}%;
          `}

          ${val.size === `auto` &&
          css`
            width: auto;
          `}

      ${val.maxSize !== undefined &&
          css`
            max-width: ${val.maxSize * chunk + mediator * 2}%;
          `}

      ${val.pushX !== undefined &&
          val.pushX !== `auto` &&
          css`
            margin-left: ${val.pushX * chunk - mediator}%;
          `}

      ${val.pushX === `auto` &&
          css`
            margin-left: auto;
          `}

      ${val.pullX !== undefined &&
          css`
            margin-right: ${val.pullX * chunk - mediator}%;
          `}

      ${val.pushY !== undefined &&
          css`
            margin-top: ${val.pushY * chunk - mediator}%;
          `}

      ${val.pullY !== undefined &&
          css`
            margin-bottom: ${val.pullY * chunk - mediator}%;
          `}
        }
      `
    )}
`

const Cell = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
)

Cell.propTypes = {
  children: PropTypes.node,
}

export default Cell
