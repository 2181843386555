import React from "react"
import { RemoveScroll } from "react-remove-scroll"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const Container = styled.div`
  height: 100%;

  > * {
    height: 100%;
    padding-bottom: 120px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    ${(props) =>
      props.disableScroll &&
      css`
        overflow-y: hidden;
      `}
  }
`

class InnerScroll extends React.Component {
  recaptchaObserverIV = null

  state = {
    recaptchaChallengeVisible: false,
  }

  componentDidMount() {
    this.recaptchaObserverIV = window.setInterval(this.recaptchaObserver, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.recaptchaObserverIV)
  }

  render() {
    const { children, className, style, ...props } = this.props

    const { recaptchaChallengeVisible } = this.state

    return (
      <Container
        className={className}
        style={style}
        disableScroll={recaptchaChallengeVisible}
      >
        <RemoveScroll {...props}>{children}</RemoveScroll>
      </Container>
    )
  }

  recaptchaObserver = () => {
    const el = document.querySelector(
      `iframe[src^="https://www.google.com/recaptcha/api2/bframe"]`
    )

    this.setState({
      recaptchaChallengeVisible:
        el && el.parentNode.parentNode.style.visibility === `visible`,
    })
  }
}

InnerScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default InnerScroll
