import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import uuid from "uuid-random"
import { rem } from "polished"

const Container = styled.section`
  @media ${(props) => props.theme.mediumDown} {
    position: relative;
    padding-bottom: ${rem(30)};

    &::before {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      left: -10vw;
      right: -10vw;
      background-color: ${(props) => props.theme.colorWhite};
      opacity: 0.1;
    }
  }

  h2 {
    margin-bottom: 0.75em;
  }
`

const Iframe = styled.iframe`
  width: 100%;
  height: 10em;
  margin-top: 1.5em;
  overflow: hidden;
`

const Newsletter = ({ data }) => {
  const iframeRef = useRef()
  const iframeIdRef = useRef(uuid())
  const iframeHeightReceivedRef = useRef(false)

  const postMessage = () => {
    if (!iframeRef.current.contentWindow) return

    iframeRef.current.contentWindow.postMessage({ action: `cintLoaded` }, `*`)
  }

  const winMessage = (e) => {
    if (
      iframeRef.current &&
      e &&
      e.data &&
      e.data.id == iframeRef.current.name &&
      e.data.height
    ) {
      iframeRef.current.style.height = `${e.data.height}px`

      if (!iframeHeightReceivedRef.current) {
        iframeHeightReceivedRef.current = true
        postMessage()
      }
    }
  }

  useEffect(() => {
    window.addEventListener(`message`, winMessage)

    if (iframeRef.current) {
      postMessage()
      iframeRef.current.addEventListener(`load`, postMessage)
    }

    return () => {
      window.removeEventListener(`message`, winMessage)
    }
  }, [])

  if (!data.url) return null

  return (
    <Container>
      {!!data.title && (
        <h2
          className="styled-p"
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
        />
      )}

      <Iframe
        ref={iframeRef}
        name={iframeIdRef.current}
        src={data.url}
        scrolling="no"
        loading="lazy"
        data-cookieconsent="ignore"
      />
    </Container>
  )
}

Newsletter.propTypes = {
  data: PropTypes.object,
}

export default Newsletter
