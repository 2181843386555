import React from "react"
import PropTypes from "prop-types"

import Meta from "../meta"

const normalizePageMeta = (wp) => {
  const acf = wp.acf || {}

  const lang = acf.lang || undefined
  const title = acf.title || wp.title || wp.name || undefined
  const titleOverridePattern = acf.titleOverridePattern || undefined
  const description =
    acf.description || wp.content || wp.description || undefined
  const socialImage =
    (acf.socialImage && acf.socialImage.localFile
      ? acf.socialImage.localFile.url
      : undefined) ||
    (wp.featured_media && wp.featured_media.localFile
      ? wp.featured_media.localFile.url
      : undefined) ||
    undefined

  const robotsNoIndex = acf.robotsNoIndex || undefined
  const robotsNoFollow = acf.robotsNoFollow || undefined

  const ogType = acf.ogType || undefined
  const ogTitle = acf.ogTitle || title || undefined
  const ogDescription = acf.ogDescription || description || undefined
  const ogImage =
    acf.ogImage && acf.ogImage.localFile
      ? acf.ogImage.localFile.url
      : socialImage

  const twitterTitle = acf.twitterTitle || title || undefined
  const twitterDescription = acf.twitterDescription || description || undefined
  const twitterCreator = acf.twitterCreator || undefined
  const twitterImage =
    acf.twitterImage && acf.twitterImage.localFile
      ? acf.twitterImage.localFile.url
      : socialImage

  const meta = {
    lang,
    title,
    titleOverridePattern,
    description,
    socialImage,
    robotsNoIndex,
    robotsNoFollow,
    ogType,
    ogTitle,
    ogDescription,
    ogImage,
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterCreator,
  }

  Object.keys(meta).forEach(
    (key) => meta[key] === undefined && delete meta[key]
  )

  return meta
}

const normalizeGlobalMeta = (wp) => {
  const meta = { ...wp.options } // deep-copy

  meta.socialImage =
    meta.socialImage && meta.socialImage.localFile
      ? meta.socialImage.localFile.url
      : undefined

  meta.ogImage =
    meta.ogImage && meta.ogImage.localFile
      ? meta.ogImage.localFile.url
      : undefined

  meta.twitterImage =
    meta.twitterImage && meta.twitterImage.localFile
      ? meta.twitterImage.localFile.url
      : undefined

  return meta
}

const MetaWp = ({ page, global, defaults, ...props }) => {
  const pageMeta = page ? normalizePageMeta(page) : {}
  const globalMeta = global ? normalizeGlobalMeta(global) : {}

  return <Meta {...globalMeta} {...pageMeta} {...props} defaults={defaults} />
}

MetaWp.propTypes = {
  page: PropTypes.object,
  global: PropTypes.object,
  defaults: PropTypes.object,
}

export default MetaWp
