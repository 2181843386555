import React from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Bar from "./bar"

const timeout = 200

const Container = styled.header`
  position: relative;
  z-index: ${(props) => props.theme.ziHeader};

  .rtg-exit-active {
    transform: translateY(-100%);
    transition: transform ${timeout}ms linear;
  }

  .rtg-enter {
    transform: translateY(-100%);
  }

  .rtg-enter-active {
    transform: translateY(0);
    transition: transform ${timeout}ms linear;
  }
`

const Sticky = styled.div`
  width: 100%;
  padding: ${rem(20)} 0;
  position: fixed;
  z-index: ${(props) => props.theme.ziHeader};
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colorWhite};

  ${(props) =>
    props.logo === `lucid` &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`

class Header extends React.Component {
  state = {
    showSticky: false,
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.winScroll, { passive: true })
    window.addEventListener(`resize`, this.winResize, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.winScroll, { passive: true })
    window.removeEventListener(`resize`, this.winResize, { passive: true })
  }

  render() {
    const { padding, largeLogo, logo, hideNav } = this.props

    const { showSticky } = this.state

    return (
      <Container>
        <Bar
          padding={padding}
          largeLogo={largeLogo}
          logo={logo}
          hideNav={hideNav}
        />

        <TransitionGroup>
          <CSSTransition
            key={showSticky}
            timeout={{ exit: timeout, enter: timeout }}
            classNames="rtg"
          >
            <React.Fragment>
              {showSticky && (
                <Sticky logo={logo}>
                  <Bar logo={logo} hideNav={hideNav} />
                </Sticky>
              )}
            </React.Fragment>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    )
  }

  winScroll = () => {
    this.setSticky()
  }

  winResize = () => {
    this.setSticky()
  }

  setSticky = () => {
    const showSticky = window.scrollY > window.innerHeight
    this.setState({ showSticky })
  }
}

Header.propTypes = {
  invert: PropTypes.bool,
  padding: PropTypes.bool,
  largeLogo: PropTypes.bool,
  logo: PropTypes.string,
  hideNav: PropTypes.bool,
}

Header.defaultProps = {
  padding: true,
  largeLogo: true,
  hideNav: false,
  logo: `cint`,
}

export default Header
