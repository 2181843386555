import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"

import "../scss/fonts/index.scss"
import "../scss/app/index.scss"
import "../config/yup"

import SiteMessage from "./site-message"
import Footer from "./footer"
// import CookiesConsent from "./cookies-consent"
import NavDialog from "./nav-dialog"
import LanguageDialog from "./language-dialog"
import { StoreProvider, StoreConsumer } from "../store"
import theme from "../theme"

if (typeof document !== `undefined`) {
  initSmartOutline([
    `input:focus`,
    `button:focus`,
    `textarea:focus`,
    `select:focus`,
  ])
}

const Layout = ({
  children,
  metaDefaults,
  metaWpGlobal,
  translations,
  menus,
  hideFooter,
  hideFooterSections,
  siteMessage,
}) => {
  const { options: wpOptions = {} } = metaWpGlobal

  return (
    <ThemeProvider theme={theme}>
      <StoreProvider
        metaDefaults={metaDefaults}
        metaWpGlobal={metaWpGlobal}
        translations={translations}
        menus={menus}
      >
        <React.Fragment>
          <Helmet>
            <meta name="format-detection" content="telephone=no" />

            <meta
              name="google-site-verification"
              content="ydc0TXYDguadPYfGsrsZby2eN76rbiuqGVAxm0602ao"
            />

            <meta
              name="google-site-verification"
              content="v1IrWyMmE2_599hArOnPsmFI_3V-sT56FcHfIqBdQIw"
            />

            <script
              src="https://cookiebot.cint.com/script.js"
              id="cint-cookiebot-script"
              data-heading={translations[`cookie_consent_text`]}
              data-accept={translations[`cookie_consent_accept`]}
              data-deny={translations[`cookie_consent_deny`]}
              data-customize={translations[`cookie_consent_customize`]}
            ></script>

            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="2516e8c1-8c66-4712-8cef-80de6c2f9172"
              data-blockingmode="auto"
              data-culture={
                wpOptions.lang.replace(`jp`, `ja`).toUpperCase() || `EN`
              }
            ></script>
          </Helmet>

          {siteMessage && <SiteMessage data={siteMessage.options} />}

          <main>{children}</main>

          {typeof hideFooter != undefined && !hideFooter && (
            <Footer
              metaWpGlobal={metaWpGlobal}
              hideSections={hideFooterSections}
            />
          )}

          {/* <CookiesConsent /> */}

          <StoreConsumer>
            {({ displayNavDialog, displayLanguageDialog }) => (
              <React.Fragment>
                {displayNavDialog && <NavDialog />}

                {displayLanguageDialog && <LanguageDialog />}
              </React.Fragment>
            )}
          </StoreConsumer>
        </React.Fragment>
      </StoreProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  metaDefaults: PropTypes.object,
  metaWpGlobal: PropTypes.object,
  translations: PropTypes.object,
  siteMessage: PropTypes.object,
  menus: PropTypes.object,
  hideFooter: PropTypes.bool,
  hideFooterSections: PropTypes.object,
}

export default Layout
