import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import filterInvalidDOMProps from "filter-invalid-dom-props"
import { startsWith } from "lodash"

const Link = ({ children, dangerouslySetInnerHTML, to, ...rest }) => {
  const isInternal = /^\/(?!\/)/.test(to)

  if (isInternal) {
    if (
      rest.partiallyActive &&
      typeof window !== `undefined` &&
      window.location.pathname !== `/`
    ) {
      rest.partiallyActive = false
    }

    return (
      <GatsbyLink
        to={to}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }

  if (rest.target && !rest.rel) {
    rest.rel = `noopener`
  } else if (startsWith(to, `tel:`) && !rest.rel) {
    rest.rel = `nofollow`
  }

  return (
    <a
      href={to}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...filterInvalidDOMProps(rest)}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  dangerouslySetInnerHTML: PropTypes.object,
  to: PropTypes.string.isRequired,
}

export default Link
