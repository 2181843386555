import React from "react"
import PropTypes from "prop-types"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

class StoreProvider extends React.Component {
  state = {
    metaDefaults: this.props.metaDefaults,
    metaWpGlobal: this.props.metaWpGlobal,
    translations: this.props.translations,
    menus: this.props.menus,
    displayNavDialog: false,
    displayLanguageDialog: false,
  }

  render() {
    const { setDisplayNavDialog, setDisplayLanguageDialog } = this

    const {
      metaDefaults,
      metaWpGlobal,
      translations,
      menus,
      displayNavDialog,
      displayLanguageDialog,
    } = this.state

    return (
      <StoreContext.Provider
        value={{
          metaDefaults,
          metaWpGlobal,
          translations,
          menus,
          displayNavDialog,
          displayLanguageDialog,

          setDisplayNavDialog,
          setDisplayLanguageDialog,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }

  setDisplayNavDialog = (val) => {
    this.setState({ displayNavDialog: val })
  }

  setDisplayLanguageDialog = (val) => {
    this.setState({ displayLanguageDialog: val })
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  metaDefaults: PropTypes.object,
  metaWpGlobal: PropTypes.object,
  translations: PropTypes.object,
  menus: PropTypes.object,
}

export { StoreContext, StoreProvider, StoreConsumer }
