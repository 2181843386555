import React from "react"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import Link from "../link"
import InnerScroll from "../inner-scroll"
import NavToggle from "../nav-toggle"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const animGrid = keyframes`
  0%   { transform: translateY(-2em); }
  100% { transform: translateY(0); }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${(props) => props.theme.ziNavFull};
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
  animation: ${(props) => props.theme.fadeIn} 0.5s linear;

  /* IE */
  button {
    overflow: visible;
  }
`

const NavToggleWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  animation: ${(props) => props.theme.fadeIn} 0.5s 0.5s forwards linear;
`

const Primary = styled.div`
  font-size: ${rem(44)};
  line-height: 0.933;
  text-align: right;

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(28)};
  }

  a,
  button {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  /* 1st level */
  > ul {
    > li:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  /* 2nd level */
  li ul {
    margin-left: 0.5em;

    li {
      margin-top: 0.25em;
    }

    a,
    button {
      opacity: 0.5;
    }
  }

  .-cta {
    position: relative;

    &::before {
      content: "";
      width: 0.3em;
      height: 0.3em;
      position: absolute;
      top: 0.4em;
      left: -0.6em;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colorRed};
    }
  }
`

const GridStyled = styled(Grid)`
  animation: ${animGrid} 0.5s ${(props) => props.theme.easingOutCubic};

  @media ${(props) => props.theme.xsmallDown} {
    padding-left: ${rem(30)};
    padding-right: ${rem(30)};
  }
`

const printLinks = (links) => (
  <StoreConsumer>
    {({ setDisplayNavDialog }) => (
      <ul>
        {links.map((link, i) => (
          <li key={i} className={link.withSeparator ? `-gap` : undefined}>
            <Link
              to={link.url}
              target={link.newWindow ? `_blank` : undefined}
              className={`${link.htmlClass} ${
                link.type === `cta` ? `-cta` : ``
              }`}
              activeClassName="styled-a"
              partiallyActive={true}
              onClick={() => setDisplayNavDialog(false)}
              dangerouslySetInnerHTML={{ __html: link.title }}
            />
          </li>
        ))}
      </ul>
    )}
  </StoreConsumer>
)

const LanguageDialog = () => {
  return (
    <StoreConsumer>
      {({ displayLanguageDialog, setDisplayLanguageDialog, menus }) => (
        <Container>
          <InnerScroll style={{ height: `100vh` }}>
            <GridStyled>
              <Row>
                <Cell size={23}>
                  <NavToggleWrap>
                    <NavToggle
                      active={true}
                      onClick={(e) => {
                        e.preventDefault()
                        setDisplayLanguageDialog(false)
                      }}
                    />
                  </NavToggleWrap>
                </Cell>
              </Row>

              <Row pushY={1}>
                <Cell size={23}>
                  <Primary>{printLinks(menus.footerLanguages)}</Primary>
                </Cell>
              </Row>
            </GridStyled>
          </InnerScroll>
        </Container>
      )}
    </StoreConsumer>
  )
}

export default LanguageDialog
