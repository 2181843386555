import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const chunk = 3.571

const Container = styled.div`
  ${`` /* outline: 1px solid black; */}

  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.pushY &&
    css`
      padding-top: ${(props) => props.pushY * chunk}%;
    `}

  ${(props) =>
    props.pullY &&
    css`
      padding-bottom: ${(props) => props.pullY * chunk}%;
    `}

  ${(props) =>
    props.mq &&
    Object.entries(props.mq).map(
      ([key, val]) => css`
        @media ${(props) => props.theme[key]} {
          ${val.pushY !== undefined &&
          css`
            padding-top: ${val.pushY * chunk}%;
          `}

          ${val.pullY !== undefined &&
          css`
            padding-bottom: ${val.pullY * chunk}%;
          `}
        }
      `
    )}
`

const Row = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
)

Row.propTypes = {
  children: PropTypes.node,
}

export default Row
