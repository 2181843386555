import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { StoreConsumer } from "../../store"

const Container = styled.div`
  button {
    width: 2em;
    height: 2em;
    display: block;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: currentColor;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      transition: transform 0.5s ${(props) => props.theme.easingOutBack};
    }

    &::before {
      transform: translateY(-0.25em);
    }
    &::after {
      transform: translateY(0.25em);
    }

    ${(props) =>
      props.active &&
      css`
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      `}
  }
`

const NavToggle = ({ active, onClick }) => {
  return (
    <StoreConsumer>
      {({ translations }) => (
        <Container active={active}>
          <button
            type="button"
            title={translations[active ? `hide_menu` : `show_menu`]}
            onClick={onClick}
          >
            <span
              className="is-vhidden"
              dangerouslySetInnerHTML={{
                __html: translations[active ? `hide_menu` : `show_menu`],
              }}
            ></span>
          </button>
        </Container>
      )}
    </StoreConsumer>
  )
}

NavToggle.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default NavToggle
