import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Base64 } from "js-base64"

const offset = 0.31
const chunk = 4.347

const Container = styled.div`
  ${`` /* outline: 1px solid blue; */}

  width: 100%;
  max-width: ${rem(1680)};
  margin: 0 auto;
  padding: ${rem(56)};
  position: relative;

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(30)} ${rem(15)};
  }

  ${(props) =>
    props.noTopPadding &&
    css`
      padding-top: 0 !important;
    `}

  ${(props) =>
    props.noBottomPadding &&
    css`
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.fillHeight &&
    css`
      ${`` /* outline: 1px solid red; */}
      min-height: 100%;
      flex: 1 1 auto;
      display: flex;
    `}

  ${(props) =>
    props.dots &&
    css`
      &::before {
        ${`` /* outline: 1px solid green; */}

        content: '';
        position: absolute;
        z-index: 1;
        top: calc(${rem(56)});
        bottom: calc(${rem(56)});
        left: calc(${rem(56)} - ${offset}%);
        right: calc(${rem(56)} - ${offset}%);
        padding-right: ${offset * 2}%;
        background-size: ${chunk}%;
        background-origin: content-box;
        background-image: url("data:image/svg+xml;base64,${(props) =>
          Base64.encode(`
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <circle
              cx="4"
              cy="4"
              r="4"
              fill="${props.dots.color}"
              fill-opacity="${props.dots.opacity || 1}"
            />
          </svg>
      `)}");

        @media ${(props) => props.theme.xsmallDown} {
          top: calc(${rem(30)});
          bottom: calc(${rem(30)});
          left: calc(${rem(15)} - 0.3105590062%);
          right: calc(${rem(15)} - 0.3105590062%);
          padding-right: 0;
          background-size: 12.95%;
        }

        ${(props) =>
          props.noTopPadding &&
          css`
            top: 0;
          `}

        ${(props) =>
          props.noBottomPadding &&
          css`
            bottom: 0;
          `}
      }
    `}
`

const Inner = styled.div`
  position: relative;
  z-index: 2;

  ${(props) =>
    props.fillHeight &&
    css`
      min-height: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    `}
`

const Grid = ({ children, dots, fillHeight, ...props }) => (
  <Container dots={dots} fillHeight={fillHeight} {...props}>
    <Inner fillHeight={fillHeight}>{children}</Inner>
  </Container>
)

Grid.propTypes = {
  children: PropTypes.node,
  dots: PropTypes.object,
  fillHeight: PropTypes.bool,
}

export default Grid
