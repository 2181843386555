import { useCookies } from "react-cookie"
import React from "react"
import { rem } from "polished"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Button from "../button"
import Link from "../link"
import { StoreConsumer } from "../../store"

const Container = styled.section`
  padding: ${rem(30)} 0;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);

  @media ${(props) => props.theme.xxlargeDown} {
    padding: ${rem(15)} 0;
  }
`

const CellMsg = styled(Cell)`
  order: 10;
  padding-top: ${rem(10)};

  @media ${(props) => props.theme.xsmallDown} {
    padding-top: 0;
  }
`

const CellCta = styled(Cell)`
  order: 10;
  text-align: right;
`

const CellClose = styled(Cell)`
  order: 10;
  text-align: right;
  padding-top: ${rem(10)};

  @media ${(props) => props.theme.mediumDown} {
    order: 1;
    padding-top: 0;
  }
`

const CloseButton = styled.button`
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  position: relative;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const SiteMessage = ({
  data: { hash, published, message, ctaLabel, ctaUrl } = {},
}) => {
  const cookieName = `site-message`
  const [cookies, setCookie] = useCookies([cookieName])

  if (
    typeof window === `undefined` ||
    !published ||
    !message ||
    cookies[cookieName] === hash
  ) {
    return null
  }

  const closeButtonClick = (e) => {
    e.preventDefault()
    setCookie(cookieName, hash)
  }

  return (
    <Container>
      <Grid noTopPadding noBottomPadding>
        <Row>
          <CellMsg
            size={15}
            mq={{
              mediumDown: { size: 14, pushY: 1 },
              xsmallDown: { size: 23 },
            }}
            className="styled"
            dangerouslySetInnerHTML={{ __html: message || `` }}
          />

          {ctaUrl && ctaLabel && (
            <CellCta
              size={5}
              pushX={1}
              mq={{
                mediumDown: { size: 7, pushX: 2, pushY: 1 },
                xsmallDown: { size: 23, pushX: 0 },
              }}
            >
              <Button
                as={Link}
                to={ctaUrl}
                target={ctaUrl.startsWith(`/`) ? undefined : `_blank`}
                size="small"
              >
                {ctaLabel}
              </Button>
            </CellCta>
          )}

          <CellClose
            size={1}
            pushX={1}
            mq={{ mediumDown: { size: 23, pushX: 0 } }}
          >
            <StoreConsumer>
              {({ translations }) => (
                <CloseButton
                  onClick={closeButtonClick}
                  title={translations[`hide_menu`]}
                  aria-label={translations[`hide_menu`]}
                />
              )}
            </StoreConsumer>
          </CellClose>
        </Row>
      </Grid>
    </Container>
  )
}

SiteMessage.propTypes = {
  data: PropTypes.object,
}

export default SiteMessage
