import React from "react"
import styled from "styled-components"
import { rem, em } from "polished"

// import NavItems from "./nav-items"
import NavItems from "./nav-items-new"
import Link from "../link"
import NavToggle from "../nav-toggle"
import { StoreConsumer } from "../../store"
import { ReactComponent as SvgLanguages } from "../../images/icons/languages.svg"
import { ReactComponent as SvgChevronDown } from "../../images/icons/chevron-down.svg"

const Container = styled.div`
  width: 100%;
`

const NavWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${rem(55)};
  position: relative;

  @media ${(props) => props.theme.xxlargeDown} {
    display: none;
  }
`

const NavToggles = styled.div`
  display: flex;
  align-items: center;

  > a,
  > button {
    margin-right: 1em;
    font-size: ${em(20)};

    &:hover {
      opacity: 0.6;
    }

    &:not(:last-child) {
      margin-right: ${rem(28)};
    }

    > svg {
      width: ${rem(12)};
      height: ${rem(12)};
      margin-left: ${rem(8)};
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  .-cta {
    &::before {
      content: "";
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.3em;
      display: inline-block;
      position: relative;
      top: 0.1em;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colorRed};
    }
  }
`

const LanguageToggle = styled.div`
  margin-left: auto;
  padding-right: 1em;

  button {
    width: 2em;
    height: 2em;
    padding: 0.2em;
    display: block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const MobileToggles = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${(props) => props.theme.xxlargeUp} {
    display: none;
  }
`

const ItemWrap = styled.div`
  position: relative;
  padding: ${rem(15)} 0;
  margin-right: ${rem(26)};

  &:hover {
    > button {
      opacity: 0.6;

      > svg {
        transform: rotate(180deg);
      }
    }

    > div {
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0);
    }
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    font-size: ${em(20)};

    &:hover {
      opacity: 0.6;
    }

    > svg {
      width: ${rem(12)};
      height: ${rem(12)};
      margin-left: ${rem(8)};
      margin-top: ${rem(5)};
      transition: transform 0.2s ease;
    }
  }
`

const DropdownActivate = styled.button``

const DropdownContainer = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 100%;
  left: ${rem(-40)};
  background-color: ${(props) => props.theme.colorWhite};
  border: 1px solid ${(props) => props.theme.colorGreyLight};
  padding: ${rem(30)} ${rem(50)} ${rem(30)} ${rem(30)};
  transform: translateY(5%);
  transition: transform 0.2s ease, opacity 0.3s ease;

  > a {
    min-width: ${rem(140)};
    white-space: nowrap;
    align-items: center;
    gap: ${rem(20)};

    &:not(:last-of-type) {
      margin-bottom: ${rem(20)};
    }
  }

  .arrow {
    position: absolute;
    overflow: hidden;
    transform: translateY(-100%);
    height: ${rem(15)};
    width: ${rem(30)};
    left: ${rem(50)};
    top: 0;

    &::after {
      content: "";
      position: absolute;
      width: ${rem(15)};
      height: ${rem(15)};
      background-color: ${(props) => props.theme.colorWhite};
      border: 1px solid ${(props) => props.theme.colorGreyLight};
      transform: translateX(-50%) translateY(50%) rotate(45deg);
      top: 0;
      left: 50%;
    }
  }
`
const printLinks = (links) => (
  <StoreConsumer>
    {() => (
      <div>
        {links.map((link, i) => (
          <ItemWrap key={i}>
            {link.wordpress_parent && (
              <DropdownActivate>
                <span dangerouslySetInnerHTML={{ __html: link.title }} />
                <SvgChevronDown aria-hidden="true" />
              </DropdownActivate>
            )}

            {link.wordpress_parent && (
              <DropdownContainer>
                <span className="arrow"></span>
                {link.wordpress_children.map((child, i) => (
                  <Link
                    key={i}
                    to={child.url_child}
                    target={child.newWindowChild ? `_blank` : undefined}
                  >
                    <span>{child.title_child}</span>
                  </Link>
                ))}
              </DropdownContainer>
            )}

            {!link.wordpress_parent && (
              <Link
                to={link.url}
                target={link.newWindow ? `_blank` : undefined}
                className={link.type === `cta` ? `-cta` : undefined}
                data-matomo={
                  link.eventCategory &&
                  JSON.stringify([
                    link.eventCategory,
                    link.eventAction,
                    link.eventName,
                  ])
                }
                dangerouslySetInnerHTML={{ __html: link.title }}
              />
            )}
          </ItemWrap>
        ))}
      </div>
    )}
  </StoreConsumer>
)

const Nav = () => {
  const LanguageToggleWrap = () => (
    <StoreConsumer>
      {({ setDisplayLanguageDialog, translations }) => (
        <LanguageToggle>
          <button
            aria-label={translations[`show_languages`]}
            title={translations[`show_languages`]}
            onClick={(e) => {
              e.preventDefault()
              setDisplayLanguageDialog(true)
            }}
          >
            <SvgLanguages />
          </button>
        </LanguageToggle>
      )}
    </StoreConsumer>
  )

  const NavToggleWrap = () => (
    <StoreConsumer>
      {({ setDisplayNavDialog }) => (
        <NavToggle
          active={false}
          onClick={(e) => {
            e.preventDefault()
            setDisplayNavDialog(true)
          }}
        />
      )}
    </StoreConsumer>
  )

  return (
    <Container>
      <NavWrap>
        <NavItems />

        <StoreConsumer>
          {({ menus }) => (
            <NavToggles>
              {printLinks(menus.headerSecondary)}

              <LanguageToggleWrap />

              <NavToggleWrap />
            </NavToggles>
          )}
        </StoreConsumer>
      </NavWrap>

      <MobileToggles>
        <LanguageToggleWrap />

        <NavToggleWrap />
      </MobileToggles>
    </Container>
  )
}

export default Nav
