import React from "react"
import styled, { keyframes } from "styled-components"
import { rem, em } from "polished"

import Link from "../link"
import InnerScroll from "../inner-scroll"
import NavToggle from "../nav-toggle"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"
import { ReactComponent as SvgLogo } from "../../images/logo.svg"

const animGrid = keyframes`
  0%   { transform: translateY(-2em); }
  100% { transform: translateY(0); }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${(props) => props.theme.ziNavFull};
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
  animation: ${(props) => props.theme.fadeIn} 0.5s linear;

  /* IE */
  button {
    overflow: visible;
  }

  svg {
    color: ${(props) => props.theme.colorRed};
    width: ${rem(146)};
    height: ${rem(80)};

    @media ${(props) => props.theme.smallDown} {
      width: ${rem(72)};
      height: ${rem(35)};
    }
  }
`

const NavToggleWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  animation: ${(props) => props.theme.fadeIn} 0.5s 0.5s forwards linear;
`

const GridStyled = styled(Grid)`
  animation: ${animGrid} 0.5s ${(props) => props.theme.easingOutCubic};

  @media ${(props) => props.theme.xsmallDown} {
    padding-left: ${rem(30)};
    padding-right: ${rem(30)};
  }
`

const Title = styled.h2`
  font-size: ${em(42)};

  @media ${(props) => props.theme.smallDown} {
    font-size: ${em(26)};
  }
`

const Primary = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.xxlargeDown} {
    margin-top: ${rem(52)};
  }

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(32)};
  }

  a {
    display: inline-flex;
    font-size: ${em(24)};
    margin-top: ${rem(24)};
    color: ${(props) => props.theme.colorGreyDark};

    &:hover {
      opacity: 0.7;
    }

    @media ${(props) => props.theme.smallDown} {
      font-size: ${em(22)};
      margin-top: ${rem(16)};
    }
  }

  .-cta {
    color: ${(props) => props.theme.colorWhite};
    font-size: ${em(42)};
    position: relative;
    margin-top: 0;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      width: 0.3em;
      height: 0.3em;
      position: absolute;
      top: 50%;
      left: -0.6em;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colorRed};
    }

    @media ${(props) => props.theme.smallDown} {
      font-size: ${em(26)};
    }
  }
`

const Secondary = styled.div`
  margin-top: ${rem(52)};
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(32)};
  }

  a {
    display: inline-flex;
    font-size: ${em(24)};
    margin-top: ${rem(24)};
    color: ${(props) => props.theme.colorGreyDark};

    &:hover {
      opacity: 0.7;
    }

    @media ${(props) => props.theme.smallDown} {
      font-size: ${em(22)};
      margin-top: ${rem(16)};
    }
  }
`

const printLinks = (links) => (
  <StoreConsumer>
    {({ setDisplayNavDialog }) => (
      <ul>
        {links.map((link, i) => (
          <li key={i} className={link.withSeparator ? `-gap` : undefined}>
            <Link
              to={link.url}
              target={link.newWindow ? `_blank` : undefined}
              className={link.type === `cta` ? `-cta` : undefined}
              data-matomo={
                link.eventCategory &&
                JSON.stringify([
                  link.eventCategory,
                  link.eventAction,
                  link.eventName,
                ])
              }
              partiallyActive={true}
              onClick={() => setDisplayNavDialog(false)}
              dangerouslySetInnerHTML={{ __html: link.title }}
            />
          </li>
        ))}
      </ul>
    )}
  </StoreConsumer>
)

const NavDialog = () => {
  return (
    <StoreConsumer>
      {({ displayNavDialog, setDisplayNavDialog, menus }) => (
        <Container>
          <InnerScroll style={{ height: `100vh` }}>
            <GridStyled>
              <Row>
                <Cell size={19}>
                  <StoreConsumer>
                    {({ translations, metaWpGlobal }) => (
                      <SvgLogo aria-label={metaWpGlobal.options.name} />
                    )}
                  </StoreConsumer>
                </Cell>

                <Cell size={3} pushX={1}>
                  <NavToggleWrap>
                    <NavToggle
                      active={true}
                      onClick={(e) => {
                        e.preventDefault()
                        setDisplayNavDialog(!displayNavDialog)
                      }}
                    />
                  </NavToggleWrap>
                </Cell>
              </Row>

              <Row>
                <Cell size={23} pushY={1} mq={{ xxlargeDown: { pushY: 0 } }}>
                  <Row>
                    <Cell
                      size={5}
                      pullX={4}
                      mq={{
                        xxlargeDown: { size: 10, pullX: 0 },
                        smallDown: { size: 23 },
                      }}
                    >
                      <Primary>
                        <StoreConsumer>
                          {() => (
                            <Title
                              dangerouslySetInnerHTML={{
                                __html: menus.headerFullPrimary.label,
                              }}
                            />
                          )}
                        </StoreConsumer>
                        {printLinks(menus.headerFullPrimary.links)}
                      </Primary>

                      <Secondary>
                        <StoreConsumer>
                          {() => (
                            <Title
                              dangerouslySetInnerHTML={{
                                __html: menus.headerFullSecondary.label,
                              }}
                            />
                          )}
                        </StoreConsumer>
                        {printLinks(menus.headerFullSecondary.links)}
                      </Secondary>
                    </Cell>

                    <Cell
                      size={5}
                      pullX={4}
                      mq={{
                        xxlargeDown: { size: 10, pullX: 0, pushX: 2 },
                        smallDown: { size: 23, pushX: 0 },
                      }}
                    >
                      <Primary>
                        <StoreConsumer>
                          {() => (
                            <Title
                              dangerouslySetInnerHTML={{
                                __html: menus.headerFullTertiary.label,
                              }}
                            />
                          )}
                        </StoreConsumer>
                        {printLinks(menus.headerFullTertiary.links)}
                      </Primary>
                    </Cell>

                    <Cell size={5} mq={{ xxlargeDown: { size: 23 } }}>
                      <Primary>
                        <StoreConsumer>
                          {() => (
                            <Title
                              dangerouslySetInnerHTML={{
                                __html: menus.headerFullQuaternary.label,
                              }}
                            />
                          )}
                        </StoreConsumer>
                        {printLinks(menus.headerFullQuaternary.links)}
                        <br />
                        {printLinks(menus.footerLanguages)}
                      </Primary>
                    </Cell>
                  </Row>
                </Cell>
              </Row>
            </GridStyled>
          </InnerScroll>
        </Container>
      )}
    </StoreConsumer>
  )
}

export default NavDialog
