import * as Yup from "yup"

// messages
// source:
// https://github.com/jquense/yup/blob/master/src/locale.js

const mixed = {
  default: `invalid`, // `invalid`
  required: `required`, // `Required`

  // currently not in use and not translated:
  oneOf: `Must be one of the following values: \${values}`,
  notType: `Must not be one of the following values: \${values}`,
  notOneOf: `Must be a \${type} type`,
}

const string = {
  email: `email`, // `Must be a valid email`
  phone: `phone`, // `Must be a valid phone number`

  // currently not in use and not translated:
  length: `Must be exactly \${length} characters`,
  min: `Must be at least \${min} characters`,
  max: `Must be at most \${max} characters`,
  matches: `Must match the following: "\${regex}"`,
  url: `Must be a valid URL`,
  uuid: `Must be a valid UUID`,
  trim: `Must be a trimmed string`,
  lowercase: `Must be a lowercase string`,
  uppercase: `Must be a upper case string`,
}

const number = {
  // currently not in use and not translated:
  min: `Must be greater than or equal to \${min}`,
  max: `Must be less than or equal to \${max}`,
  lessThan: `Must be less than \${less}`,
  moreThan: `Must be greater than \${more}`,
  notEqual: `Must be not equal to \${notEqual}`,
  positive: `Must be a positive number`,
  negative: `Must be a negative number`,
  integer: `Must be an integer`,
}

const date = {
  // currently not in use and not translated:
  min: `Must be later than \${min}`,
  max: `Must be at earlier than \${max}`,
}

const boolean = {
  termsAndConditions: `terms_and_conditions`, // `Must accept terms and conditions`
}

const object = {
  // currently not in use and not translated:
  noUnknown: `Has unspecified keys: \${unknown}`,
}

const array = {
  // currently not in use and not translated:
  min: `Must have at least \${min} items`,
  max: `Must have less than or equal to \${max} items`,
}

Yup.setLocale({
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})

// custom methods

Yup.addMethod(Yup.boolean, `termsAndConditions`, function (message) {
  return this.test(
    `termsAndConditions`,
    message || boolean.termsAndConditions,
    (value) => {
      return value === true
    }
  )
})

Yup.addMethod(Yup.string, `phone`, function (message) {
  return this.test(`phone`, message || string.phone, (value) => {
    return (
      /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(value) &&
      6 < value.length &&
      18 > value.length
    )
    // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript/4338544#comment90042129_33561517
  })
})
