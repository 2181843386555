import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

const Container = styled.button`
  font-size: ${rem(18)};
  padding: 0.75em 1.25em;
  display: inline-block;
  text-align: center;

  @media ${(props) => props.theme.mediumDown} {
    padding: 0.6em 1em;
    font-size: ${rem(16)};
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
  }

  &[disabled] {
    opacity: 0.5;
  }

  ${`` /* DISPLAY */}

  ${(props) =>
    props.display === `block` &&
    css`
      width: 100%;
      display: block;
    `}

  ${`` /* SIZE */}

    ${(props) =>
    props.size === `small` &&
    css`
      padding: 0.6em 1em;
      font-size: ${rem(16)};

      @media ${(props) => props.theme.mediumDown} {
        padding: 0.5em 0.8em;
        font-size: ${rem(14)};
      }
    `}

    ${(props) =>
    props.size === `large` &&
    css`
      padding: 0.6em 1em;
      font-size: ${rem(22)};

      @media ${(props) => props.theme.mediumDown} {
        padding: 0.5em 0.8em;
        font-size: ${rem(18)};
      }
    `}

  ${`` /* COLORS */}

  ${(props) =>
    !props.color &&
    css`
      color: ${(props) => props.theme.colorWhite};
      background-color: ${(props) => props.theme.colorRed};
    `}

  ${(props) =>
    props.color === `white` &&
    css`
      color: ${(props) => props.theme.colorPurple};
      background-color: ${(props) => props.theme.colorWhite};
    `}

  ${(props) =>
    props.color === `greyDark` &&
    css`
      color: ${(props) => props.theme.colorPurple};
      background-color: ${(props) => props.theme.colorGreyDark};
    `}

  ${(props) =>
    props.color === `purple` &&
    css`
      color: ${(props) => props.theme.colorWhite};
      background-color: ${(props) => props.theme.colorPurple};
    `}

  ${`` /* BACKGROUND STYLE */}

  ${(props) =>
    props.backgroundStyle &&
    css`
      background-color: transparent;
      border: 1px solid currentColor;
    `}

  ${(props) =>
    props.backgroundStyle == `hollow` &&
    props.color === `purple` &&
    css`
      color: ${(props) => props.theme.colorPurple};
    `}

  ${(props) =>
    props.backgroundStyle == `hollow` &&
    props.color === `white` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}
`

const Button = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

Button.propTypes = {
  children: PropTypes.node,
}

export default Button
